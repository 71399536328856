import LandscapeOption from "./LandscapeOption";
import {Styled} from "./Landscapes.styles";
import {useTranslation} from "react-i18next";

type LandscapesProps = {
    options: any[]
}

const Landscapes = (
    {
        options
    }: LandscapesProps
) => {

    const {t} = useTranslation()

    const onShowMoreLandscapesClickHandler = () => {
        alert('add handling')
    }

    return (
        <Styled.Landscape__Content__Holder>
            {options.map((option: any) => (
                <LandscapeOption
                    option={option}
                />
            ))}
            <Styled.Show__More__Landscapes__Button
                onClick={onShowMoreLandscapesClickHandler}
                label={t('lbl_show_more_landscapes')}
            />
        </Styled.Landscape__Content__Holder>
    )
}

export default Landscapes