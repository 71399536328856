import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Text from "../../lib/UI/Text";
import {rgba} from "polished";

const Footer__Holder = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
  border-top: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
`
const Site__Map__Holder = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
  border-top: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
`
const Footer__Map__Site__Title = styled(Text)`
  text-align: center;
  margin: 20px auto;
  font-weight: bold;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`


export const Styled = {
    Footer__Map__Site__Title,
    Site__Map__Holder,
    Footer__Holder
}