import PageHolder from "../../../lib/UI/PageHolder";
import {Styled} from "./Advertisement.styles";
import {faRectangleAd} from "@fortawesome/pro-solid-svg-icons";
import MainPageAdvertisement from "./AdvertisementTypes/MainPage/MainPageAdvertisment";

const Advertisement = () => {


    return (
        <PageHolder>
            <Styled.Advertisement__Holder>
                <Styled.Advertisement__Header>
                    <Styled.Advertisement__Title>
                        <Styled.Advertisement__Icon
                            icon={faRectangleAd}
                        />
                        Advertisement
                    </Styled.Advertisement__Title>
                </Styled.Advertisement__Header>
            </Styled.Advertisement__Holder>
            <MainPageAdvertisement/>
        </PageHolder>
    )
}

export default Advertisement