import useMainPage from "../useMainPage";
import {Styled} from "./BottomNonPromotableArticles";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {chunk} from "lodash-es";

const resizeFunc = () => {
    if (window.innerWidth < 1000) {
        return 4
    }
    if (window.innerWidth < 700) {
        return 3
    }
    if (window.innerWidth < 600) {
        return 2
    }
    return 5
}

const BottomNonPromotableArticles = () => {

    const [rowsNumber, setRowsNumber] = useState<number>(resizeFunc())

    const {bottomNonPromotedArticles} = useMainPage()

    const {t} = useTranslation()

    useEffect(() => {
        window.addEventListener('resize', () => {
            setRowsNumber(resizeFunc())
        })
        return () => {
            window.removeEventListener('resize', () => {
                setRowsNumber(resizeFunc())
            })
        }
    }, [])

    return (
        <Styled.Bottom__Articles__Holder>
            <Styled.Section__Title>
                {t('lbl_bottom_articles')}
            </Styled.Section__Title>
            {chunk(bottomNonPromotedArticles, rowsNumber).map((options: any, index: number) => (
                <Styled.Bottom__Articles__Row
                    key={'bottom_articles_row_' + index}
                >
                    {options.map((option: any) => (
                        <Styled.Bottom__Article
                            key={'bottom_article_' + option?.titl}
                        >
                            <Styled.Bottom__Article__Location>
                                {option?.location}
                            </Styled.Bottom__Article__Location>
                            <Styled.Article__Title>
                                {option.title}
                            </Styled.Article__Title>
                        </Styled.Bottom__Article>
                    ))}

                </Styled.Bottom__Articles__Row>
            ))}
        </Styled.Bottom__Articles__Holder>
    )
}
export default BottomNonPromotableArticles