import Drawer from "../../lib/UI/Drawer/Drawer";
import useNavigation from "../../lib/Hooks/useNavigation";
import WorldOfRegionsLogo from "../WorldOfRegionsLogo";
import {Styled} from "./Navigation.styles";
import {Admin__Page} from "../../lib/Const/Routes/Routes";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SeasonPicker from "./SeasonPicker";
import LanguagePicker from "./LanguagePicker";

const NavigationDrawer = () => {

    const {
        isOpenDrawer,
        navigationOptions,
        businessNavigationOptions,
        toggleNavigationDrawer
    } = useNavigation()

    const {t} = useTranslation()

    const location = useLocation()

    const onClose = () => {
        toggleNavigationDrawer(false)
    }

    const getOptionList = () => {

        if (location.pathname.includes(Admin__Page)) {
            return businessNavigationOptions
        }

        return navigationOptions

    }

    return (
        <Drawer
            title={<WorldOfRegionsLogo/>}
            onClose={onClose}
            isOpen={isOpenDrawer}
            position={"right"}
        >
            <Styled.Navigation__Drawer__Content__Holder>
                <SeasonPicker/>
                <Styled.Language__Section__Title>
                    {t('lbl_change_language')}
                </Styled.Language__Section__Title>
                <LanguagePicker
                    isDrawer={true}
                />
                {getOptionList().map((option: any) => {

                    const {label, list} = option
                    return (
                        <Styled.Navigation__Drawer__Section__Holder
                            key={`navigation_drawer_option_${label}`}
                        >
                            <Styled.Navigation__Drawer__Section__Title>
                                {label}
                            </Styled.Navigation__Drawer__Section__Title>
                            {list.map((subOption: any, index: number) => {
                                const {title, icon, onClick} = subOption
                                const onSubOptionClick = () => {
                                    onClick()
                                    onClose()
                                }

                                return (
                                    <Styled.Navigation__Drawer__Sub__Option
                                        onClick={onSubOptionClick}
                                        key={'subOption_drawer' + title}
                                    >
                                        <Styled.SubOption__Icon
                                            icon={icon}
                                        />
                                        <Styled.SubOption__Title>
                                            {title}
                                        </Styled.SubOption__Title>
                                        {list.length - 1 !== index && (
                                            <Styled.SubOption__Separator__Line/>
                                        )}
                                    </Styled.Navigation__Drawer__Sub__Option>
                                )
                            })}
                        </Styled.Navigation__Drawer__Section__Holder>
                    )
                })}


            </Styled.Navigation__Drawer__Content__Holder>
        </Drawer>
    )
}
export default NavigationDrawer