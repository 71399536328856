import {Styled} from "./Advertisement.styles"

const AdvertisementBox = () => {

    return (
        <Styled.Advertisement__Box__Holder>
            <Styled.Advertisement__Anchor>
                <Styled.Advert__Image
                    src={'https://i0.wp.com/digital-photography-school.com/wp-content/uploads/2021/04/panorama-photography-1001-1.jpg?fit=1500%2C375&ssl=1'}
                />

            </Styled.Advertisement__Anchor>
        </Styled.Advertisement__Box__Holder>
    )
}

export default AdvertisementBox