import useStorage from "./useStorage";

const useSessionPageConfig = () => {

    const [state, setState] = useStorage<any>('sessionStorage', 'session_page_config', {})


    return {
        state,
        setState
    }

}


export default useSessionPageConfig