import {Styled} from "./Hotels.styles"
import {useTranslation} from "react-i18next";
import HotelOption from "./HotelOption";
import useMainPage from "../useMainPage";

const Hotels = () => {

    const {t} = useTranslation()

    const {hotelsOptions} = useMainPage()

    return (
        <Styled.Hotels__Content__Holder>
            <Styled.Section__Title>
                {t('lbl_promoted_hotels')}
            </Styled.Section__Title>
            <Styled.Hotels__Options__Holder>
                {hotelsOptions.map((option: any) => {

                    return (
                        <HotelOption
                            option={option}
                        />
                    )
                })}

            </Styled.Hotels__Options__Holder>
        </Styled.Hotels__Content__Holder>
    )
}
export default Hotels