import CountryBox from "../../../../Pages/ContinentPage/CountryBox";
import {Styled} from "./Places.styles";
import TextInput from "../../../../lib/UI/TextInput";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {isEmpty} from "lodash-es";

type PlacesProps = {
    options: any
}


const Places = (
    {
        options
    }: PlacesProps
) => {

    const defaultStateValue = {
        name: '',
        options: options
    }

    const [placesControls, setPlacesControls] = useState<any>(defaultStateValue)

    const {t} = useTranslation()

    const onInputChangeHandler = (event: any) => {
        const value = event.target.value
        setPlacesControls((state: any) => ({
            ...state,
            name: value
        }))
    }

    useEffect(() => {
        if (!!placesControls.name.trim()) {
            setPlacesControls((state: any) => ({
                ...state,
                options: options.filter((option: any) => option.title.toLowerCase().includes(placesControls.name.toLowerCase()))
            }))
        } else if (placesControls.options !== options) {
            setPlacesControls((state: any) => ({
                ...state,
                options
            }))
        }
    }, [placesControls.name])

    return (
        <Styled.Places__Section__Holder>
            <Styled.Places__Filter__Input__Holder>
                <Styled.TextInput__Title>
                    {t('lbl_places_text_input_title')}
                </Styled.TextInput__Title>
                <TextInput
                    showIcon={false}
                    onChange={onInputChangeHandler}
                    value={placesControls.name}
                    placeholder={t('lbl_places_placeHolder')}
                />
            </Styled.Places__Filter__Input__Holder>
            {!isEmpty(placesControls.options) ? (
                <Styled.Places__Content__Holder>
                    {placesControls.options.map((place: any) => {

                        return (
                            <CountryBox
                                key={'place_option' + place?.title}
                                option={place}
                            />
                        )

                    })}
                </Styled.Places__Content__Holder>
            ) : (
                <Styled.Places__Empty__Options>
                    {t('lbl_places_options_empty')}
                </Styled.Places__Empty__Options>
            )}
        </Styled.Places__Section__Holder>
    )
}

export default Places