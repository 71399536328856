import {Styled} from "./Hotels.styles";
import {faStar} from "@fortawesome/pro-solid-svg-icons";

type HotelOptionProps = {
    option: any
}

const HotelOption = (
    {
        option
    }: HotelOptionProps
) => {

    const {
        rating,
        image,
        title,
        city
    } = option

    return (
        <Styled.Hotel__Option>
            <Styled.Hotel__Image
                src={image}
            />
            <Styled.Hotel__lower__Content__Holder>
                <Styled.Location__Rating__Holder>
                    <Styled.Location__Name>
                        {city}
                    </Styled.Location__Name>
                    <Styled.Hotels__Rating__Holder>
                        {Array.from({length: rating}).map((_: any, index: number) => (
                            <Styled.Rating__Star
                                key={'hotel_option_' + title + 'star'}
                                icon={faStar}
                            />
                        ))}
                    </Styled.Hotels__Rating__Holder>
                </Styled.Location__Rating__Holder>
                <Styled.Hotel__Title>
                    {title}
                </Styled.Hotel__Title>
            </Styled.Hotel__lower__Content__Holder>
        </Styled.Hotel__Option>
    )
}
export default HotelOption