import {Styled} from "./Navigation.styles";
import WorldOfRegionsLogo from "../WorldOfRegionsLogo";
import {Main__First__Page} from "../../lib/Const/Routes/Routes";
import {useNavigate} from "react-router-dom";
import Tooltip from "../../lib/UI/Tooltip";
import NavigationToolTipContents from "./NavigationToolTipContent";
import {faBars} from "@fortawesome/pro-solid-svg-icons";
import useNavigation from "../../lib/Hooks/useNavigation";
import useTimeOfYear from "../../lib/Hooks/useTimeOfYear";
import useUserConfig from "../../lib/Hooks/useUserConfig";
import {typeChange} from "./SeasonLanguageModal";

const PublicNavigation = () => {

    const {
        toggleNavigationDrawer,
        toggleChangeSeason,
        navigationOptions
    } = useNavigation()

    const {
        languageData
    } = useUserConfig()

    const onBarsOnClickHandler = () => {
        toggleNavigationDrawer(true)
    }

    const onSeasonClickHandler = () => {
        toggleChangeSeason(typeChange.season)
    }

    const onLanguageClickHandler = () => {
        toggleChangeSeason(typeChange.language)
    }

    const navigate = useNavigate()

    const {currentSeasonConfig} = useTimeOfYear()

    const wideScreenContent = (
        <Styled.Navigation__Wide__Screen__Holder>
            {navigationOptions.map((option: any) => (
                <Tooltip
                    trigger={'onclick, mouseenter'}
                    key={`navigation_bar_option_${option.label}`}
                    interactive={true}
                    disabled={!option.list}
                    placement={'bottom-start'}
                    content={<NavigationToolTipContents list={option.list}/>}
                >
                    <Styled.Navigation__Wide__Screen__Option>
                        {option.label}
                    </Styled.Navigation__Wide__Screen__Option>
                </Tooltip>
            ))}

            <Styled.Round__Navigation__Language__Season__Picker>
                <Styled.Language__Holder>
                    <Styled.Flag__Holder
                        onClick={onLanguageClickHandler}
                    >
                        <Styled.Language__Flag
                            src={languageData?.flag ?? ''}
                        />
                    </Styled.Flag__Holder>
                </Styled.Language__Holder>
                <Styled.Season__Holder>
                    <Styled.Navigation__Time__Of__Year
                        isSelected={true}
                        onClick={onSeasonClickHandler}
                        color={currentSeasonConfig.color}
                        icon={currentSeasonConfig.icon}
                    />
                </Styled.Season__Holder>
            </Styled.Round__Navigation__Language__Season__Picker>

        </Styled.Navigation__Wide__Screen__Holder>
    )

    const smallScreenContent = (
        <Styled.Navigation__Small__Screen__Holder>
            <Styled.Menu__Bars__Icon
                icon={faBars}
                onClick={onBarsOnClickHandler}
            />
        </Styled.Navigation__Small__Screen__Holder>
    )


    return (
        <>
            <Styled.Logo__Positioner>
                <WorldOfRegionsLogo
                    onClick={() => navigate(Main__First__Page)}
                />
            </Styled.Logo__Positioner>
            {smallScreenContent}
            {wideScreenContent}
        </>
    )
}

export default PublicNavigation