import {Styled} from "./Navigation.styles";
import {seasons, seasonsConfig} from "../../lib/Const/Configs/TimeOfYearConf";
import useTimeOfYear from "../../lib/Hooks/useTimeOfYear";
import {useTranslation} from "react-i18next";
import {toLower} from "lodash-es";

type SeasonPicker = {
    onChange?: (selectedSeason: seasons) => void
}

export const SEASON_EVENT = "onSeasonChange"

const SeasonPicker = (
    {
        onChange
    }: SeasonPicker) => {

    const {currentSeasons, setUserSeason} = useTimeOfYear()

    const {t} = useTranslation()

    const onOptionClickHandler = (type: seasons) => {
        if (currentSeasons !== type) {
            const customEvent = new CustomEvent(SEASON_EVENT, {
                detail: {
                    type: toLower(type),
                },
            });
            window.dispatchEvent(customEvent);
            setUserSeason(type)
            if (onChange) {
                onChange(type)
            }
        }
    }

    return (
        <Styled.Navigation__Drawer__Seasons__Holder
            backgroundImage={seasonsConfig[currentSeasons].backgroundImage}
        >
            {Object.keys(seasonsConfig).map((season: any) => {

                const {color, icon, type} = seasonsConfig[season]
                return (
                    <Styled.Navigation__Drawer__Season__Option
                        onClick={() => onOptionClickHandler(type)}
                        key={'drawer_seasons' + season}

                    >
                        <Styled.Navigation__Time__Of__Year
                            color={color}
                            icon={icon}
                            isSelected={type.toString() === currentSeasons.toString()}
                            style={{
                                margin: '0px auto',
                                borderRadius: '50%'
                            }}
                        />
                        <Styled.Season__Title>
                            {t('lbl_time_of_year_' + season.toLowerCase())}
                        </Styled.Season__Title>
                    </Styled.Navigation__Drawer__Season__Option>
                )
            })}

        </Styled.Navigation__Drawer__Seasons__Holder>
    )
}
export default SeasonPicker