import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../lib/UI/Text";
import Image from "../../lib/UI/Image";
import {rgba} from "polished";

const Navigation__Place__Holder = styled(FlexBox)`
  width: 100%;
  height: 60px;
  border-bottom: 1px solid transparent;
`
const Navigation__Fixed__Holder = styled(FlexBox)`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 60px;
  background-color: ${({theme}) => theme.backgroundColor};
  border-bottom: 1px solid ${({theme}) => theme.color};
`
const Navigation__Content__Holder = styled(FlexBox)`
  padding: 0 10px;
  height: 100%;
`
const Navigation__Wide__Screen__Holder = styled(FlexBox)`
  display: none;
  height: 100%;
  flex-grow: 1;
  @media (min-width: 600px) {
    display: flex;
    flex-wrap: nowrap;
    gap: 0 25px;
    overflow: hidden;
    margin-left: 30px;
  }
`
const Navigation__Small__Screen__Holder = styled(FlexBox)`
  display: flex;
  height: 100%;
  margin-left: auto;
  @media (min-width: 600px) {
    display: none;
  }
`
const Logo__Positioner = styled(FlexBox)`
  margin: auto 0;
`
const Menu__Bars__Icon = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.color};
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin: auto 0;
`
const Navigation__Wide__Screen__Option = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: auto 0;
  cursor: pointer;
`
const Navigation__Drawer__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  min-width: 380px;
  padding-bottom: 10px;
  @media (max-width: 400px) {
    min-width: calc(100vw - 20px);
  }
`
const Navigation__Drop__Down = styled(FlexBox)`
  flex-direction: column;
  min-width: 200px;
  padding: 5px;
  gap: 10px;
`
const Navigation__Drop__Down__Option = styled(Text)`
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

`
const Navigation__Drop__Down__Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  height: 19px;
  width: 19px;
`
const Business__Navigation__Holder = styled(FlexBox)`
  width: 100%;
  flex-wrap: nowrap;
`
const Business__Navigation__Icon = styled(Image)`
  width: 50px;
  height: 50px;
  background-color: transparent;
`
const Business__Logo__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  margin: auto 0;
  cursor: pointer;
`

const Business__Navigation__Title = styled(Text)`
  margin-left: 8px;
  font-size: 18px;
  margin-top: auto;
  margin-bottom: auto;
  white-space: nowrap;
`
const Navigation__Time__Of__Year = styled(FontAwesomeIcon)<{ color: string, isSelected?: boolean }>`
  height: 28px;
  width: 28px;
  padding: 12px;
  margin-top: -25px;
  rotate: 45deg;
  -webkit-filter: grayscale(${({isSelected}) => isSelected ? '0' : '90'}%);
  background-color: ${({color}) => color};
  color: ${({theme}) => theme.color};
  cursor: pointer;
  transition: 200ms;

  &:hover {
    -webkit-filter: grayscale(0%);
  }

`
const Navigation__Drawer__Seasons__Holder = styled(FlexBox)<{ backgroundImage: string }>`
  padding: 60px 10px;
  justify-content: space-between;
  gap: 20px;
  margin: 0 auto;
  height: 100%;
  align-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-color: ${({theme}) => rgba(theme.color, 0.2)};
  background-image: url(${({backgroundImage}) => backgroundImage});
  transition: background 500ms;
`
const Navigation__Drawer__Season__Option = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  margin: auto 0;
  width: 80px;
`
const Season__Title = styled(Text)`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
`
const Navigation__Drawer__Section__Title = styled(Text)`
  font-size: 22px;
  font-weight: bold;
  margin: 8px 10px;
`
const Navigation__Drawer__Section__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Navigation__Drawer__Sub__Option = styled(FlexBox)`
  padding: 15px;
  flex-wrap: nowrap;
  position: relative;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }
`
const SubOption__Icon = styled(FontAwesomeIcon)`
  height: 25px;
  width: 25px;
  color: ${({theme}) => theme.color};
`
const SubOption__Title = styled(Text)`
  font-size: 18px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
`
const SubOption__Separator__Line = styled(FlexBox)`
  position: absolute;
  width: calc(100% - 40px);
  bottom: 0px;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
`
const Language__Picker__Holder = styled(FlexBox)<{ isDrawer: boolean }>`
  max-width: ${({isDrawer}) => isDrawer ? '380px' : 'calc(100% - 20px)'};
  max-height: ${({isDrawer}) => isDrawer ? '175px' : 'unset'};
  overflow: hidden;
  align-self: center;
  gap: 10px;
  align-items: center;

`
const Language__Option = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  cursor: pointer;
  width: 80px;
  margin: 0 auto;
`
const Language__Image = styled(Image)`
  width: 55px;
  height: 55px;
  margin: 0 auto;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
`
const Language__Title = styled(Text)<{ isSelected: boolean }>`
  margin: 0 auto;
  padding-top: 5px;
  font-size: 16px;
  font-weight: ${({isSelected}) => isSelected && 'bold'};
`
const Language__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 15px 10px;
`
const Language__Show__More = styled(Text)`
  margin-left: auto;
  margin-top: 5px;
  text-decoration: underline;
  cursor: pointer;
`
const Language__Icon = styled(FontAwesomeIcon)`
  margin-left: 3px;
`
const Language__Section__Title = styled(Text)`
  font-size: 22px;
  font-weight: bold;
  padding: 0 10px;
  margin-top: 15px;
  margin-bottom: 5px;
`
const Round__Navigation__Language__Season__Picker = styled(FlexBox)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.color};
  overflow: hidden;
  margin: auto 0;
  margin-left: auto;
`
const Language__Holder = styled(FlexBox)`
  width: 60px;
  height: 25px;
  margin-top: 5px;
  margin-left: -16px;
  rotate: -45deg;
`
const Season__Holder = styled(FlexBox)`
  min-width: 60px;
  height: 25px;
  margin-left: 5px;
  margin-top: -20px;
  overflow: hidden;
  rotate: -45deg;
`
const Language__Flag = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`
const Flag__Holder = styled(FlexBox)`
  width: 52px;
  height: 52px;
  rotate: 45deg;
  cursor: pointer;
  margin-left: 7px;
`


export const Styled = {
    Flag__Holder,
    Language__Flag,
    Season__Holder,
    Language__Holder,
    Round__Navigation__Language__Season__Picker,
    Language__Section__Title,
    Language__Icon,
    Language__Show__More,
    Language__Content__Holder,
    Language__Title,
    Language__Image,
    Language__Option,
    Language__Picker__Holder,
    SubOption__Separator__Line,
    SubOption__Title,
    SubOption__Icon,
    Navigation__Drawer__Sub__Option,
    Navigation__Drawer__Section__Holder,
    Navigation__Drawer__Section__Title,
    Season__Title,
    Navigation__Drawer__Season__Option,
    Navigation__Drawer__Seasons__Holder,
    Navigation__Time__Of__Year,
    Business__Navigation__Title,
    Business__Logo__Holder,
    Business__Navigation__Icon,
    Business__Navigation__Holder,
    Navigation__Drop__Down__Icon,
    Navigation__Drop__Down__Option,
    Navigation__Drop__Down,
    Navigation__Drawer__Content__Holder,
    Navigation__Wide__Screen__Option,
    Menu__Bars__Icon,
    Logo__Positioner,
    Navigation__Small__Screen__Holder,
    Navigation__Wide__Screen__Holder,
    Navigation__Place__Holder,
    Navigation__Fixed__Holder,
    Navigation__Content__Holder
}