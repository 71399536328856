import {useContext} from "react";
import {Navigation__Context} from "../Context/ContextList";
import {
    faBriefcase,
    faClipboardQuestion,
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
    faGlobeOceania,
    faLightbulb,
    faMagnifyingGlass,
    faNewspaper,
    faUserTie
} from "@fortawesome/pro-light-svg-icons";
import {useNavigate} from "react-router-dom";
import {
    Admin__Advertisement,
    Admin__Billing,
    Admin__Page,
    All__Articles,
    Business__Become__Supplier,
    Business_FAQ,
    Discover__World__No__Param,
    Travel__Tips__No__Param
} from "../Const/Routes/Routes";
import {typeChange} from "../../components/Navigation/SeasonLanguageModal";

const useNavigation = () => {

    const [navigationState, setNavigationState] = useContext<any>(Navigation__Context)

    const navigate = useNavigate()

    const toggleNavigationDrawer = (bool: boolean) => {
        setNavigationState((state: any) => ({
            ...state,
            isOpenDrawer: bool
        }))
    }

    const toggleChangeSeason = (type: typeChange | undefined) => {
        setNavigationState((state: any) => ({
            ...state,
            isOpenWideScreenDrawer: type
        }))
    }

    const navigationOptions = [
        {
            label: 'Discover',
            list: [
                {
                    title: 'Latest Articles',
                    icon: faNewspaper,
                    onClick: () => {
                        navigate(All__Articles)
                    }
                },
                {
                    title: 'Travel Tips',
                    icon: faLightbulb,
                    onClick: () => {
                        navigate(Travel__Tips__No__Param + '/General')
                    }
                },
                {
                    title: "Where To Go",
                    icon: faMagnifyingGlass,
                    onClick: () => {
                        navigate(Discover__World__No__Param + '/Panel')
                    }
                },

            ]
        },
        {
            label: 'World',
            list: [
                {
                    title: 'Europe',
                    onClick: () => {
                        navigate('Europe')
                    },
                    icon: faGlobeEurope
                },
                {
                    title: 'Asia',
                    onClick: () => {
                        navigate('Asia')
                    },
                    icon: faGlobeAsia
                },
                {
                    title: 'Africa',
                    onClick: () => {
                        navigate('Africa')
                    },
                    icon: faGlobeAfrica
                },
                {
                    title: 'America',
                    onClick: () => {
                        navigate('America')
                    },
                    icon: faGlobeAmericas
                },
                {
                    title: 'Oceania',
                    onClick: () => {
                        navigate('Oceania')
                    },
                    icon: faGlobeOceania
                }
            ]
        },
        {
            label: 'Business',
            list: [
                {
                    title: 'Admin Panel',
                    onClick: () => {
                        navigate(Admin__Page)
                    },
                    icon: faUserTie
                },
                {
                    title: 'Become Supplier',
                    onClick: () => {
                        navigate(Business__Become__Supplier)
                    },
                    icon: faBriefcase
                },
                {
                    title: 'FAQ For Business',
                    onClick: () => {
                        navigate(Business_FAQ)
                    },
                    icon: faClipboardQuestion
                },
            ]
        }

    ]

    const businessNavigationOptions: any = [
        {
            label: 'Main Panel',
            onClick: () => navigate(Admin__Page)
        },
        {
            label: 'Italy Page',
            onClick: () => navigate(Admin__Page)

        },
        {
            label: 'Advertisement',
            onClick: () => navigate(Admin__Advertisement)

        },
        {
            label: 'Billing',
            onClick: () => navigate(Admin__Billing)
        }
    ]

    return ({
        toggleNavigationDrawer,
        toggleChangeSeason,
        navigationOptions,
        businessNavigationOptions,
        isOpenDrawer: navigationState.isOpenDrawer,
        isOpenWideScreenDrawer: navigationState?.isOpenWideScreenDrawer
    })
}
export default useNavigation