import {seasons, seasonsConfig} from "../Const/Configs/TimeOfYearConf";
import useSessionPageConfig from "./useSessionPageConfig";
import {isEmpty} from "lodash-es";
import moment from "moment";

const useTimeOfYear = () => {

    const {state, setState} = useSessionPageConfig()

    const {season} = state

    const getCurrentTimeOfYear = () => {
        if (!isEmpty(season)) {
            return season
        } else {
            const month = moment().month()

            if (month >= 2 && month <= 4) {
                return seasons.spring;
            } else if (month >= 5 && month <= 7) {
                return seasons.summer;
            } else if (month >= 8 && month <= 10) {
                return seasons.fall;
            } else {
                return seasons.winter;
            }
        }

    }

    const setUserSeason = (userSelected: seasons) => {
        setState((state: any) => ({
            ...state,
            season: userSelected
        }))
    }

    return {
        currentSeasons: getCurrentTimeOfYear(),
        setUserSeason,
        currentSeasonConfig: seasonsConfig[getCurrentTimeOfYear()]
    }
}

export default useTimeOfYear