import {useParams} from "react-router-dom";
import {Styled} from "./CountryPage.styles";
import PageHolder from "../../lib/UI/PageHolder";
import SectionTabs from "../../components/Sections/SectionTabs";
import {useTranslation} from "react-i18next";
import Section from "../../components/Sections/Section";
import React, {createElement, useEffect, useRef, useState} from "react";
import Overview from "../../components/Sections/SectionTypes/Overview";
import {geoJSOn} from "../../components/InterActiveMap/mapvalue";
import ImagesGallery from "../../components/ImagesGallery";
import PageTitle from "../../components/PageTitle";
import {sectionTypeEnum} from "../../components/Sections/SectionTypes/TravelTips/DescriptionContentPart";
import TopFiveDestinations from "../../components/Sections/SectionTypes/TopFiveDestinations";
import {WOR_CONTENT_SERVICE_BASE_URL} from "../../lib/Requests/requestsSetup";
import axios from "axios";
import {useDebouncedCallback} from "use-debounce";
import {includes, toLower} from "lodash-es";
import useTimeOfYear from "../../lib/Hooks/useTimeOfYear";
import Landscapes from "../../components/Sections/SectionTypes/Landscapes";
import TravelTips from "../../components/Sections/SectionTypes/TravelTips";
import Artciles from "../../components/Sections/SectionTypes/Artciles";
import TravelInformation from "../../components/Sections/SectionTypes/TravelInformation";
import Regions from "./Regions";
import useCustomEventListener from "../../lib/Hooks/useCustomEventListener";
import {SEASON_EVENT} from "../../components/Navigation/SeasonPicker";


enum countrySections {
    SiteData = "SiteData",
    Description = 'Description',
    TopFive = 'TopFive',
    TravelInformation = 'TravelInformation',
    RegionList = 'RegionList',
    Landscapes = "Landscapes",
    PlanTrip = "PlanTrip",
    Articles = "Articles"
}

const CountryPage = () => {

    const {t} = useTranslation()

    const regionList = [
        {
            title: 'Lombardy',
            link: window.location.origin + window.location.pathname + '/Lombardy',
            image: 'https://www.wine-searcher.com/images/region/lombardy-3574-1-1.jpg',
            flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Flag_of_Lombardy.svg/800px-Flag_of_Lombardy.svg.png',
            details: {
                capital: 'Milan',
                population: '10 million',
                area: '23,844 sq km',
                notableCities: ['Milan', 'Bergamo', 'Como'],
                famousFor: ['Fashion', 'Finance', 'Lakes'],
                landmarks: ['Duomo di Milano', 'Lake Como', 'Sforza Castle']
            }
        },
        {
            title: 'Lazio',
            link: window.location.origin + window.location.pathname + '/Lazio',
            image: 'https://www.italia.it/content/dam/tdh/en/interests/lazio/roma/roma-la-citta-eterna/media/20210410125258-enit-lazio-roma-tevere.jpg',
            flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Flag_of_Lazio.svg/1280px-Flag_of_Lazio.svg.png',
            details: {
                capital: 'Rome',
                population: '5.8 million',
                area: '17,207 sq km',
                notableCities: ['Rome', 'Latina', 'Viterbo'],
                famousFor: ['History', 'Art', 'Cuisine'],
                landmarks: ['Colosseum', 'Vatican City', 'Trevi Fountain']
            }
        },
        {
            title: 'Campania',
            link: window.location.origin + window.location.pathname + '/Campania',
            image: 'https://www.wine-searcher.com/images/region/campania-3925-1-2.jpg',
            flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Flag_of_Campania.svg/1200px-Flag_of_Campania.svg.png',
            details: {
                capital: 'Naples',
                population: '5.8 million',
                area: '13,590 sq km',
                notableCities: ['Naples', 'Salerno', 'Sorrento'],
                famousFor: ['Pizza', 'Mount Vesuvius', 'Amalfi Coast'],
                landmarks: ['Pompeii', 'Mount Vesuvius', 'Amalfi Coast']
            }
        },
        {
            title: 'Tuscany',
            link: window.location.origin + window.location.pathname + '/Tuscany',
            image: 'https://vervewine.com/cdn/shop/articles/need-to-know-tuscany_1170x.jpg?v=1589561605',
            flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Flag_of_Tuscany.svg/1200px-Flag_of_Tuscany.svg.png',
            details: {
                capital: 'Florence',
                population: '3.7 million',
                area: '22,990 sq km',
                notableCities: ['Florence', 'Pisa', 'Siena'],
                famousFor: ['Art', 'Wine', 'Countryside'],
                landmarks: ['Duomo', 'Uffizi Gallery', 'Leaning Tower of Pisa']
            }
        },
        {
            title: 'Veneto',
            link: window.location.origin + window.location.pathname + '/Veneto',
            image: 'https://www.winetraveler.com/wp-content/uploads/2018/07/veneto-wine-region-italy-facts-information.jpg',
            flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Flag_of_Veneto.svg/800px-Flag_of_Veneto.svg.png',
            details: {
                capital: 'Venice',
                population: '4.9 million',
                area: '18,390 sq km',
                notableCities: ['Venice', 'Verona', 'Padua'],
                famousFor: ['Canals', 'Carnival', 'Palladian Villas'],
                landmarks: ['St. Mark\'s Basilica', 'Rialto Bridge', 'Arena di Verona']
            }
        },
        {
            "title": "Umbria",
            "link": window.location.origin + window.location.pathname + '/Umbria',
            "image": "https://www.tripsavvy.com/thmb/LB4mV8qBDJ0r9_NpM6b1yIORTxE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/basilica-of-san-francesco-in-assisi--umbria--italy-921370774-5aba5929ae9ab80037aa6325.jpg",
            "flag": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Flag_of_Umbria.svg/1280px-Flag_of_Umbria.svg.png",
            "details": {
                "capital": "Perugia",
                "population": "Approximately 882,000",
                "area": "8,456 sq km",
                "notableCities": ["Perugia", "Assisi", "Terni"],
                "famousFor": ["Hilltop Towns", "Religious Sites", "Umbrian Cuisine"],
                "landmarks": ["Basilica of Saint Francis of Assisi", "Palazzo dei Priori (Perugia)", "Cascata delle Marmore"]
            }
        },
        {
            "title": "Marche",
            "link": window.location.origin + window.location.pathname + '/Marche',
            "image": "https://www.italiapozaszlakiem.com/wp-content/uploads/2023/02/Cupramontana-Marche.jpeg",
            "flag": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/07/Flag_of_Marche.svg/640px-Flag_of_Marche.svg.png",
            "details": {
                "capital": "Ancona",
                "population": "1.5 million",
                "area": "9,366 sq km",
                "notableCities": ["Ancona", "Pesaro", "Ascoli Piceno"],
                "famousFor": ["Coastline", "Wine", "Cuisine"],
                "landmarks": ["Grotte di Frasassi", "Monte Conero", "Urbino"]
            }
        },
        {
            "title": "Abruzzo",
            "link": window.location.origin + window.location.pathname + '/Abruzzo',
            "image": "https://italiasweetitalia.com/wp-content/uploads/revslider/the-magical-village-of-scanno1.jpg",
            "flag": "https://www.drapeaux-flags.com/images/drapeaux/500px_norm/ABRUZZO.png",
            "details": {
                "capital": "L'Aquila",
                "population": "1.3 million",
                "area": "10,831 sq km",
                "notableCities": ["L'Aquila", "Pescara", "Chieti"],
                "famousFor": ["Mountains", "National Parks", "Traditional Cuisine"],
                "landmarks": ["Gran Sasso", "Majella National Park", "Castel del Monte"]
            }
        },
        {
            "title": "Molise",
            "link": window.location.origin + window.location.pathname + '/Molise',
            "image": "https://www.italiarail.com/sites/default/files/inline-images/molise_0.jpg",
            "flag": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Molise.svg/640px-Flag_of_Molise.svg.png",
            "details": {
                "capital": "Campobasso",
                "population": "0.3 million",
                "area": "4,438 sq km",
                "notableCities": ["Campobasso", "Isernia", "Termoli"],
                "famousFor": ["Rural Landscape", "Authentic Cuisine", "Truffle Festivals"],
                "landmarks": ["Castello Monforte", "Santa Maria del Canneto Sanctuary", "Termoli Old Town"]
            }
        },
        {
            "title": "Puglia",
            "link": window.location.origin + window.location.pathname + '/Puglia',
            "image": "https://lp-cms-production.imgix.net/2024-02/GettyImages-1223038396-RFC.jpg",
            "flag": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Flag_of_Apulia.svg/1200px-Flag_of_Apulia.svg.png",
            "details": {
                "capital": "Bari",
                "population": "4 million",
                "area": "19,357 sq km",
                "notableCities": ["Bari", "Lecce", "Brindisi"],
                "famousFor": ["Trulli Houses", "Olive Oil", "Salento Peninsula"],
                "landmarks": ["Alberobello", "Castel del Monte", "Trani Cathedral"]
            }
        },
        {
            "title": "Basilicata",
            "link": "https://en.wikipedia.org/wiki/Basilicata",
            "image": "https://i.guim.co.uk/img/media/0a801e8faa27a6e30719f00f827d696193c5529e/0_192_5616_3370/master/5616.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=64b4b88cce08388e939b584a5b999c2d",
            "flag": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Flag_of_Basilicata.svg/1280px-Flag_of_Basilicata.svg.png",
            "details": {
                "capital": "Potenza",
                "population": "0.55 million",
                "area": "9,992 sq km",
                "notableCities": ["Potenza", "Matera"],
                "famousFor": ["Sassi di Matera", "Lucanian Cuisine", "Pollino National Park"],
                "landmarks": ["Sassi di Matera", "Pollino National Park", "Castelmezzano"]
            }
        },
        {
            "title": "Calabria",
            "link": "https://en.wikipedia.org/wiki/Calabria",
            "image": "https://lp-cms-production.imgix.net/2019-06/503844741_full.jpg",
            "flag": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Flag_of_Calabria.svg/1200px-Flag_of_Calabria.svg.png",
            "details": {
                "capital": "Catanzaro",
                "population": "1.95 million",
                "area": "15,080 sq km",
                "notableCities": ["Reggio Calabria", "Cosenza", "Tropea"],
                "famousFor": ["Beautiful Coastlines", "Calabrese Cuisine", "Aspromonte National Park"],
                "landmarks": ["Tropea", "Aspromonte National Park", "Reggio Calabria Waterfront"]
            }
        },
        {
            "title": "Sicilia",
            "link": window.location.origin + window.location.pathname + '/Sicilia',
            "image": "https://content.api.news/v3/images/bin/f82dfbbabbe5522db96a14d65e91c2e6",
            "flag": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Sicilian_Flag.svg/800px-Sicilian_Flag.svg.png",
            "details": {
                "capital": "Palermo",
                "population": "5 million",
                "area": "25,711 sq km",
                "notableCities": ["Catania", "Messina", "Siracusa"],
                "famousFor": ["Mount Etna", "Historical Sites", "Sicilian Cuisine"],
                "landmarks": ["Valley of the Temples", "Mount Etna", "Taormina"]
            }
        }


    ]

    const countryArticles: any = [
        {
            title: 'Exploring Italy\'s Timeless Artistry',
            image: 'https://images.squarespace-cdn.com/content/v1/55ee34aae4b0bf70212ada4c/1551805564468-SXHQ1OAOP97XDL1PB381/Trevi+Fountain+Rome+Italy.jpg',
            time: '15:41',
            date: '28.03.2024',
            shortDesc: "Italy, a country of unparalleled culture",
            link: window.location.origin + window.location.pathname + '/' + 'Dolce_Vita'
        },
        {
            title: 'A Culinary Odyssey Through Its Regions',
            image: 'https://www.hotelmousai.com/blog/wp-content/uploads/2021/12/Top-10-Traditional-Foods-in-Italy.jpg',
            time: '18:41',
            date: '19.03.2024',
            shortDesc: "Italy, place where everything is delicious",
            link: window.location.origin + window.location.pathname + '/' + 'Pizza_Pasta'
        },
    ]

    const fuckingMapContent: any = {
        mapStyle: 'mapbox://styles/mksbrd/cltvgzxuc009i01qs9vl62rzl',
        initialState: {
            latitude: 42.5,
            longitude: 12.5,
            zoom: 5
        },
        geoJson: geoJSOn
    }

    const sectionTabs: any = {
        [countrySections.TravelInformation]: {
            data: {
                population: {
                    value: '59,11 mln',
                    type: 'text'
                },
                capital: {
                    value: 'Rome',
                    type: 'text'
                },
                currency: {
                    value: 'Euro €',
                    type: 'text'
                },
                food: {
                    value: 'Pizza, Pasta',
                    type: 'text'
                },
                numberOfRegions: {
                    value: 39,
                    type: 'text'
                },
                availAbleHotels: {
                    value: 11023,
                    type: 'text'
                },
                govLink: {
                    value: 'https://www.governo.it/en',
                    type: 'link'
                }
            },
        },
        [countrySections.PlanTrip]: {
            values: [
                {
                    image: 'https://i.guim.co.uk/img/media/e994bb7ab8b900b70a4ac07026f85ba6ac06e25e/0_248_7360_4415/master/7360.jpg?width=1200&quality=85&auto=format&fit=max&s=775e8347a5a62a6d5bef19bd670bc3ba',
                    title: 'Hiking',
                    options: [
                        {
                            type: sectionTypeEnum.plainText,
                            title: t('lbl_about_place_travel_tip'),
                            options: 'Italy\'s mountains offer a stunning backdrop to its landscapes, boasting a diverse range of peaks, valleys, and alpine scenery. The Italian Alps dominate the northern border, featuring iconic summits such as the majestic Matterhorn and Monte Rosa. In the central regions, the Apennine Range stretches across the peninsula, offering scenic hiking trails and picturesque villages nestled among rolling hills. These mountains are not just for adventurers; they also host unique flora and fauna, including alpine flowers, ibex, and chamois. Italy\'s mountainous terrain provides both recreational opportunities and a rich natural heritage to explore and appreciate.'
                        },
                        {
                            type: sectionTypeEnum.list,
                            title: t('lbl_most_known_locations_travel_tips'),
                            options: [
                                'Dolomites',
                                'Monte Bianco',
                                'Gran Paradiso',
                                'Adamello-Presanella',
                                'Sella Group'
                            ]
                        },
                        {
                            type: sectionTypeEnum.plainText,
                            title: t('lbl_flora_fauna_travel_tip'),
                            options: 'Italy\'s mountainous regions boast diverse flora and fauna. Alpine flora, such as edelweiss and alpine gentian, dot the rugged landscapes, while forests of beech, chestnut, and pine thrive at lower elevations. Fauna includes the elusive ibex, chamois, and red deer, alongside smaller mammals like foxes and marmots. Birds like golden eagles and alpine choughs soar overhead, while streams teem with trout and other aquatic life. Italy\'s mountain ecosystems are not only rich in biodiversity but also contribute to the country\'s cultural and ecological heritage, attracting nature enthusiasts and conservation efforts alike.'
                        },
                        {
                            type: sectionTypeEnum.list,
                            title: t('lbl_what_to_take_travel_tip'),
                            options: [
                                'Appropriate Clothing:',
                                'Navigation Tools',
                                'First Aid Kit',
                                'Food and Water',
                                'Camera',
                                'Communication Device'
                            ]
                        },

                    ]
                },
                {
                    image: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Ski_Famille_-_Family_Ski_Holidays.jpg',
                    title: 'Skiing',
                    options: [
                        {
                            type: sectionTypeEnum.plainText,
                            title: t('lbl_about_place_travel_tip'),
                            options: 'Skiing in Italy\'s mountains offers exhilarating slopes amid stunning scenery. From the Dolomites\' dramatic peaks to the iconic resorts of Aosta Valley and the Italian Alps, there\'s terrain for all levels. Enjoy après-ski with hearty cuisine and explore off-slope activities like snowshoeing and ice climbing. Whether seeking family fun or challenging runs, Italy\'s resorts promise unforgettable winter adventures.'
                        },
                        {
                            type: sectionTypeEnum.list,
                            title: t('lbl_most_known_locations_travel_tips'),
                            options: [
                                'Cortina d\'Ampezzo',
                                'Courmayeur',
                                'Livigno',
                                'Val Gardena',
                                'Madonna di Campiglio',
                                'Sestriere'
                            ]
                        },
                        {
                            type: sectionTypeEnum.multipleList,
                            title: t('lbl_dos_donts_travel_tips'),
                            options: [
                                {
                                    title: 'Dos',
                                    options: [
                                        'Check weather and avalanche conditions before heading out.',
                                        'Respect mountain etiquette and yield to downhill skiers.',
                                        'Stay hydrated and protect against sunburn with sunscreen and sunglasses',
                                        'Adhere to resort rules and safety guidelines, including wearing helmets.'
                                    ]
                                },
                                {
                                    title: 'Don\'ts',
                                    options: [
                                        'Don\'t venture off-piste without proper equipment and knowledge.',
                                        'Don\'t underestimate the altitude; acclimate gradually to avoid altitude sickness.',
                                        'Don\'t litter or disturb wildlife; respect the mountain environment.',
                                        'Don\'t ski under the influence of alcohol or drugs.',
                                        'Don\'t ignore signs or closures; they\'re there for your safety.'
                                    ]
                                },
                            ]
                        },
                        {
                            type: sectionTypeEnum.list,
                            title: t('lbl_what_to_take_travel_tip'),
                            options: [
                                'Appropriate Clothing:',
                                'Navigation Tools',
                                'First Aid Kit',
                                'Food and Water',
                                'Camera',
                                'Communication Device'
                            ]
                        },

                    ]
                },
                {
                    image: 'https://hips.hearstapps.com/hmg-prod/images/canal-grande-with-basilica-di-santa-maria-della-royalty-free-image-1568738188.jpg?crop=1.00xw:0.918xh;0,0.0816xh&resize=980:*',
                    title: 'City Breaks',
                    options: [
                        {
                            type: sectionTypeEnum.plainText,
                            title: t('lbl_about_place_travel_tip'),
                            options: 'Italy\'s mountains offer a stunning backdrop to its landscapes, boasting a diverse range of peaks, valleys, and alpine scenery. The Italian Alps dominate the northern border, featuring iconic summits such as the majestic Matterhorn and Monte Rosa. In the central regions, the Apennine Range stretches across the peninsula, offering scenic hiking trails and picturesque villages nestled among rolling hills. These mountains are not just for adventurers; they also host unique flora and fauna, including alpine flowers, ibex, and chamois. Italy\'s mountainous terrain provides both recreational opportunities and a rich natural heritage to explore and appreciate.'
                        },
                        {
                            type: sectionTypeEnum.list,
                            title: t('lbl_most_known_locations_travel_tips'),
                            options: [
                                'Dolomites',
                                'Monte Bianco',
                                'Gran Paradiso',
                                'Adamello-Presanella',
                                'Sella Group'
                            ]
                        },
                        {
                            type: sectionTypeEnum.plainText,
                            title: t('lbl_flora_fauna_travel_tip'),
                            options: 'Italy\'s mountainous regions boast diverse flora and fauna. Alpine flora, such as edelweiss and alpine gentian, dot the rugged landscapes, while forests of beech, chestnut, and pine thrive at lower elevations. Fauna includes the elusive ibex, chamois, and red deer, alongside smaller mammals like foxes and marmots. Birds like golden eagles and alpine choughs soar overhead, while streams teem with trout and other aquatic life. Italy\'s mountain ecosystems are not only rich in biodiversity but also contribute to the country\'s cultural and ecological heritage, attracting nature enthusiasts and conservation efforts alike.'
                        },
                        {
                            type: sectionTypeEnum.list,
                            title: t('lbl_what_to_take_travel_tip'),
                            options: [
                                'Appropriate Clothing:',
                                'Navigation Tools',
                                'First Aid Kit',
                                'Food and Water',
                                'Camera',
                                'Communication Device'
                            ]
                        },

                    ]
                },
                {
                    image: 'https://www.travelandleisure.com/thmb/PtkzpF17oxHfxuTbsnUx7oJY20A=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/lake-tahoe-california-USLAKES0920-59df9ea26eaf4bbba7620eb604f0af3c.jpg',
                    title: 'Lakes',
                    options: [
                        {
                            type: sectionTypeEnum.plainText,
                            title: t('lbl_about_place_travel_tip'),
                            options: 'Italy boasts an enchanting array of lakes, each with its own allure. Lake Como, nestled against the Alps, captivates with its serene beauty and luxurious villas. Garda, Italy\'s largest lake, entices visitors with its sparkling waters and charming lakeside towns. Maggiore enchants with its elegant promenades and picturesque islands. Trasimeno, surrounded by rolling hills, offers a tranquil retreat in the heart of Umbria. From the romantic vistas of Orta to the hidden gems of Iseo, Italy\'s lakes invite exploration, promising unforgettable experiences amidst stunning natural landscapes and cultural riches.'
                        },
                        {
                            type: sectionTypeEnum.list,
                            title: t('lbl_most_known_locations_travel_tips'),
                            options: [
                                'Lake Como',
                                'Lake Garda',
                                'Lake Maggiore',
                                'Lake Orta',
                                'Lake Iseo'
                            ]
                        },
                        {
                            type: sectionTypeEnum.plainText,
                            title: t('lbl_flora_fauna_travel_tip'),
                            options: 'The Italian lakes boast diverse flora and fauna, adding to their natural charm. Lush vegetation lines their shores, including cypress trees, olive groves, and colorful flowers. Wildlife thrives in these habitats, with species such as herons, ducks, and swans gracing the lakeshores. In the surrounding forests and mountains, one can find deer, foxes, and a variety of bird species. The lakes also support aquatic life, including fish like trout and pike, contributing to the rich ecological tapestry of these enchanting landscapes.'
                        },
                        {
                            type: sectionTypeEnum.list,
                            title: t('lbl_what_to_take_travel_tip'),
                            options: [
                                'SPF cream',
                                'Light Jacket or Sweater',
                                'Comfortable Footwear',
                                'Swimwear',
                                'Camera',
                                'Water Bottle'
                            ]
                        },

                    ]
                },
                {
                    image: 'https://files.holidaycottages.co.uk/blogs%2F1653382626498-porthcurno_cornwall_alina.png',
                    title: 'Sea Sides',
                    options: [
                        {
                            type: sectionTypeEnum.plainText,
                            title: t('lbl_about_place_travel_tip'),
                            options: 'Italy\'s coastline is a tapestry of beauty, offering stunning beaches, charming villages, and rich cultural heritage. From the rugged cliffs of the Amalfi Coast to the sandy shores of Sardinia, the sea is an integral part of Italian life. Visitors can explore historic ports like Cinque Terre, relax on the glamorous beaches of the Amalfi Coast, or dive into the crystal-clear waters of Sicily. Each region boasts its own distinct character, cuisine, and attractions, making Italy\'s seaside a must-see destination for travelers seeking sun, sand, and la dolce vita.'
                        },
                        {
                            type: sectionTypeEnum.list,
                            title: t('lbl_most_known_locations_travel_tips'),
                            options: [
                                'Dolomites',
                                'Monte Bianco',
                                'Gran Paradiso',
                                'Adamello-Presanella',
                                'Sella Group'
                            ]
                        },
                        {
                            type: sectionTypeEnum.plainText,
                            title: t('lbl_flora_fauna_travel_tip'),
                            options: 'The Italian lakes boast diverse flora and fauna, adding to their natural charm. Lush vegetation lines their shores, including cypress trees, olive groves, and colorful flowers. Wildlife thrives in these habitats, with species such as herons, ducks, and swans gracing the lakeshores. In the surrounding forests and mountains, one can find deer, foxes, and a variety of bird species. The lakes also support aquatic life, including fish like trout and pike, contributing to the rich ecological tapestry of these enchanting landscapes.'
                        },
                        {
                            type: sectionTypeEnum.list,
                            title: t('lbl_what_to_take_travel_tip'),
                            options: [
                                'SPF cream',
                                'Light Jacket or Sweater',
                                'Comfortable Footwear',
                                'Swimwear',
                                'Camera',
                                'Water Bottle'
                            ]
                        },

                    ]
                }
            ]

        },
        [countrySections.Landscapes]: {
            customTitle: 'lbl_Landscapes',
            options: [
                {
                    image: 'https://hispanico.pl/wp-content/uploads/2023/05/wulkan-etna-widok-pejzaz-dym-natura-przyroda-wlochy-europa.jpg',
                    title: 'Mount Etna',
                    description: 'One of the oldest and still active vulcanos in europe'
                },
                {
                    image: 'https://lp-cms-production.imgix.net/2024-02/Italy-Sicily-Palermo-Photon-Photos-GettyImages-1931315329-RF.jpg?auto=format&q=75&w=1920',
                    title: 'Palermo',
                    description: 'Capital of Sicily and not only'
                },
                {
                    image: 'https://lp-cms-production.imgix.net/2024-02/Italy-Sicily-Favignana-Maremagnum-GettyImages-595333295-RF.jpg?auto=format&q=75&w=1920',
                    title: 'Valley of the Temples',
                    description: 'UNESCO’s temple logo is a mirror of star-turn Tempio della Concordia'
                }
            ]
        },
        [countrySections.RegionList]: {
            options: regionList,
            ...fuckingMapContent
        },
        [countrySections.Articles]: {
            options: countryArticles
        },
    }

    const defaultState: any = {
        loadingSections: [
            countrySections.SiteData,
            countrySections.Description,
            countrySections.RegionList,
            countrySections.TopFive,
            countrySections.Landscapes,
            countrySections.PlanTrip,
            countrySections.TravelInformation,
            countrySections.Articles,
        ]
    }

    const [countryContent, setCountryContent] = useState(defaultState)

    const {currentSeasons} = useTimeOfYear()

    const {countryData} = useParams<any>()

    const refList = useRef<any>({})

    const onTabSelected = (tab: string) => {
        refList.current[tab].scrollIntoView({behavior: 'smooth', block: 'nearest'})
    }

    const sections: any = {
        [countrySections.Description]: Overview,
        [countrySections.TopFive]: TopFiveDestinations,
        [countrySections.RegionList]: Regions,
        [countrySections.Landscapes]: Landscapes,
        [countrySections.PlanTrip]: TravelTips,
        [countrySections.TravelInformation]: TravelInformation,
        [countrySections.Articles]: Artciles,
    }

    const sectionRequestConfig: any = {
        [countrySections.SiteData]: {
            route: '/Country/General',
        },
        [countrySections.Description]: {
            route: '/Country/Description'
        },
        [countrySections.RegionList]: {
            route: '/Country/RegionList'
        },
        [countrySections.TopFive]: {
            route: '/Country/TopFive'
        },
        [countrySections.Landscapes]: {
            route: '/Country/Landscapes'
        },
        [countrySections.PlanTrip]: {},
        [countrySections.TravelInformation]: {},
        [countrySections.Articles]: {},
    }

    const fetchData = async (extraDetails?: any) => {
        console.log({extraDetails})
        const query: any = {
            id: countryData?.split('_')[1],
            language: 'en',
            season: extraDetails?.detail?.type ?? toLower(currentSeasons)
        };
        setCountryContent((state: any) => ({
            ...state,
            loadingSections: Object.keys({[countrySections.SiteData]: {}, ...sections})
        }))
        const results = await Promise.all(
            Object.keys({[countrySections.SiteData]: {}, ...sections}).map(async (section) => {
                try {
                    const requestConfig = sectionRequestConfig[section]
                    const response = await axios.get(`${WOR_CONTENT_SERVICE_BASE_URL}${requestConfig?.route ?? ''}?query=${JSON.stringify(query)}`);
                    return {section, data: response.data};
                } catch (error) {
                    console.error(`Error fetching data for section ${section}:`, error);
                    setCountryContent((state: any) => ({
                        ...state,
                        loadingSections: [
                            ...state.loadingSections.filter((item: string) => item !== section)
                        ]
                    }))
                    return {section, data: null};
                } finally {
                    setCountryContent((state: any) => ({
                        ...state,
                        loadingSections: [
                            ...state.loadingSections.filter((item: string) => item !== section)
                        ]
                    }))
                }
            })
        );

        const dataMap = results.reduce((acc: any, {section, data}) => {
            acc[section] = data;
            return acc;
        }, {});

        setCountryContent((state: any) => ({
            ...state,
            ...dataMap,
            season: toLower(currentSeasons)
        }))
    };

    const fetchPage = useDebouncedCallback(() => {
        fetchData();
    }, 50)

    useCustomEventListener(SEASON_EVENT, fetchData)

    useEffect(() => {
        fetchPage()
    }, [])

    console.log({countryContent})

    return (
        <PageHolder
            backgroundImage={countryContent[countrySections.SiteData]?.backgroundImage}
        >
            <PageTitle
                isLoading={includes(countryContent.loadingSections, countrySections.SiteData)}
                title={countryContent[countrySections.SiteData]?.name as string}
                flag={countryContent[countrySections.SiteData]?.flag}
            />
            <ImagesGallery
                isLoading={includes(countryContent.loadingSections, countrySections.SiteData)}
                flag={countryContent[countrySections.SiteData]?.flag}
                images={countryContent[countrySections.SiteData]?.images ?? []}
            />
            <Styled.Country__Separator/>
            <SectionTabs
                isLoading={includes(countryContent.loadingSections, countrySections.SiteData)}
                customTitles={{value: sectionTabs, props: {place: countryData?.split('_')[0]}}}
                tabs={Object.keys(sections)}
                onClick={onTabSelected}
            />
            {Object.keys(sections).map((section: string) => (
                <Section
                    content={sections[section] ?
                        createElement(
                            sections[section] as any,
                            {
                                //countryContent
                                ...sectionTabs[section] ?? countryContent[section],
                                isLoading: includes(countryContent.loadingSections, section)
                            },
                        ) : null
                    }
                    ref={refList}
                    sectionName={section}
                    title={t(`lbl_${section}`, {place: countryData?.split('_')[0]})}
                />

            ))}
        </PageHolder>
    )
}
export default CountryPage