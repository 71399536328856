import styled from "styled-components";
import FlexBox from "../../../../lib/UI/FlexBox";
import {isMobileOnly} from "react-device-detect";
import Text from "../../../../lib/UI/Text";

const Places__Content__Holder = styled(FlexBox)`
  flex-direction: initial;
  gap: 10px;
`
const Places__Section__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
`
const Places__Filter__Input__Holder = styled(FlexBox)`
  margin: 15px 0;
  flex-direction: column;
  max-width: ${isMobileOnly ? 'unset' : '400px'};
`
const TextInput__Title = styled(Text)`
  margin-bottom: 6px;
`
const Places__Empty__Options = styled(Text)`
  padding: 40px 0;
  text-align: center;
  font-weight: bold;
  font-size: 19px;
`
export const Styled = {
    Places__Empty__Options,
    TextInput__Title,
    Places__Filter__Input__Holder,
    Places__Content__Holder,
    Places__Section__Holder
}