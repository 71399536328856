import {Styled} from "./CountryPage.styles"
import InterActiveMap from "../../components/InterActiveMap";
import React from "react";
import Places from "../../components/Sections/SectionTypes/Places";

type Regions = {
    options: any[]
    mapStyle: string
    initialState: any
    geoJson: any
}

const Regions = (
    {
        options,
        mapStyle,
        initialState,
        geoJson
    }: Regions
) => {


    return (
        <Styled.Region__Holder>
            <Styled.Country__Map>
                <InterActiveMap
                    mapStyle={mapStyle}
                    initialState={initialState}
                    geoJson={geoJson}
                />
            </Styled.Country__Map>
            <Styled.Country__Separator/>
            <Styled.Places__Holder>
                <Places
                    options={options}
                />
            </Styled.Places__Holder>
        </Styled.Region__Holder>
    )
}


export default Regions