import {Styled} from "./Footer.styles"
import PageHolder from "../../lib/UI/PageHolder";
import Links from "./components/Links";
import MapSite from "./components/MapSite";
import HoldingInformation from "./components/HoldingInformation";
import {useLocation} from "react-router-dom";

const Footer = () => {

    const {pathname} = useLocation()

    return !pathname.includes('Business') ? (
        <footer>
            <PageHolder>
                <Styled.Footer__Holder>
                    <Links/>
                    <MapSite/>
                    <HoldingInformation/>
                </Styled.Footer__Holder>
            </PageHolder>
        </footer>
    ) : null
}

export default Footer