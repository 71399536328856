import {useContext} from "react";
import {Main__Page__Context} from "../../lib/Context/ContextList";

const useMainPage = () => {

    const mainPage = useContext<any>(Main__Page__Context)

    return ({
        hotelsOptions: mainPage?.hotelsOptions,
        quickIdeas: mainPage?.SideContent?.quickIdeas,
        secondArticles: mainPage?.secondArticles,
        firstArticles: mainPage?.firstArticles,
        imagesList: mainPage?.imagesList,
        bottomNonPromotedArticles: mainPage?.bottomNonPromotedArticles
    })
}
export default useMainPage